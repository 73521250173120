import icon_create_mystories from "../images/icon_create_mystories.png";
import icon_create_signage from "../images/icon_create_signage.png";
import icon_create_myqr from "../images/icon_create_myqr.png";
import icon_create_myguide from "../images/icon_create_myguide.png";

export const DRAFT_KEYS = {
  mystories: "mystories",
  mystories_v2: "mystories_v2",
  mysignage: "mysignage",
  myqr: "myqr",
  myguide: "myguide",
  mystorybook: "mystorybook",
};

export const DRAFT_TYPES = {
  [DRAFT_KEYS.mystories]: {
    key: DRAFT_KEYS.mystories,
    title: "MyStories",
    subtitle: "Snappy interactive stories",
    image: icon_create_mystories,
    iframeId: `${DRAFT_KEYS.mystories}-iframe`,
    collection: "drafts_and_stories",
    storageFolder: "stories",
    hasChapters: true,
  },
  [DRAFT_KEYS.mystories_v2]: {
    key: DRAFT_KEYS.mystories_v2,
    title: "MyStories v2.0 (Beta)",
    subtitle: "Snappy interactive stories",
    image: icon_create_mystories,
    iframeId: `${DRAFT_KEYS.mystories_v2}-iframe`,
    collection: "drafts_and_stories_v2",
    storageFolder: "storiesV2",
    hasChapters: true,
  },
  // tryme: {
  //   key: "tryme",
  //   title: "TryMe",
  // },
  [DRAFT_KEYS.mysignage]: {
    key: DRAFT_KEYS.mysignage,
    title: "MySignage",
    subtitle: "Video and image playlists",
    image: icon_create_signage,
    iframeId: `${DRAFT_KEYS.mysignage}-iframe`,
    collection: "drafts_and_signage",
    storageFolder: "signage",
    hasChapters: true,
  },
  [DRAFT_KEYS.myqr]: {
    key: DRAFT_KEYS.myqr,
    title: "MyQR",
    subtitle: "Redirect to any website",
    image: icon_create_myqr,
    iframeId: `${DRAFT_KEYS.myqr}-iframe`,
    collection: "drafts_and_qr",
    storageFolder: "qr",
    hasChapters: false,
  },
  [DRAFT_KEYS.myguide]: {
    key: DRAFT_KEYS.myguide,
    title: "MyGuide (Beta)",
    subtitle: "Guided Experiences",
    image: icon_create_myguide,
    iframeId: `${DRAFT_KEYS.myguide}-iframe`,
    collection: "drafts_and_guide",
    storageFolder: "guide",
    hasChapters: true,
  },
  [DRAFT_KEYS.mystorybook]: {
    key: DRAFT_KEYS.mystorybook,
    title: "My Storybook (Beta)",
    subtitle: "A Collection of your Experiences",
    image: icon_create_myguide,
    iframeId: `${DRAFT_KEYS.mystorybook}-iframe`,
    collection: "drafts_and_storybook",
    storageFolder: "storybook",
    hasChapters: false,
  },
};
