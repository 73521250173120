import React, { useEffect, useState, useCallback } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import SearchIcon from "@material-ui/icons/Search";
import ViewCarouselOutlinedIcon from "@material-ui/icons/ViewCarouselOutlined";
import ErrorIcon from "@material-ui/icons/ErrorOutline";

import {
  ProjectCard,
  PullUpPanel,
  InfiniteScroll,
  EmptyData,
  Spinner,
} from "components";

import { listProjects } from "services/DraftService";
import { useMobileLayout } from "services/UiService";
import STRINGS from "strings/general";
import AUTH_STRINGS from "strings/auth";
import { debounce } from "utils";

const LIMIT_DEFAULT = 20;

const ProjectContent = styled.div`
  padding: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-template-rows: min-content;
  width: 100%;
  overflow-y: auto;
`;

const LoadingMoreContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 80px;
  justify-content: center;
  align-items: center;
`;

/**
 * SelectProjectPanel component.
 *
 * @param {boolean} open - Determines if the dialog is open or not.
 * @param {function} onClose - Function to close the dialog. This function is typically called when the user clicks outside the dialog or presses the close button.
 * @param {function} onSelect - Function to call when a project is selected. It receives two arguments:
 *   - id: The ID of the selected project.
 *   - info: An object containing information about the selected project.
 */
const SelectProjectPanel = ({ open, onClose, onSelect }) => {
  const mobile = useMobileLayout();
  const { enqueueSnackbar } = useSnackbar();

  const [projectsIds, setProjectsIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [isFetchedAll, setIsFetchedAll] = useState(false);

  const [startAfter, setStartAfter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState(false);

  const showSearch = projectsIds?.length > 0 || searchValue;

  // reset data
  const resetData = () => {
    setIsFetchedAll(false);
    setStartAfter();
  };

  const fetchData = useCallback(
    async (params, isLoadMore = false) => {
      const requestParams = { limit: LIMIT_DEFAULT, searchValue, ...params };

      if (!isLoadMore) {
        setLoading(true);
      }

      try {
        const responseData = await listProjects({
          ...requestParams,
        });

        // set is fetched all if no more projects
        // to prevent fetching on scroll
        // means no more projects to fetch
        if (responseData.length === 0) {
          setIsFetchedAll(true);
        }

        // add new projects to list
        if (params?.lastKey) {
          setProjectsIds((prevIds) => [
            ...new Set(prevIds.concat(responseData)),
          ]);
        } else {
          // set new projects list
          setProjectsIds(responseData);
        }

        // set start after for load more
        if (responseData.length) {
          setStartAfter(responseData[responseData.length - 1]);
        }

        // set loading to false if not load more
        if (!isLoadMore) {
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        // reset loading if error
        setLoading(false);
        setLoadingMore(false);
        enqueueSnackbar(AUTH_STRINGS.error, { variant: "error" });
        setError(true);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue]
  );

  // load projects on open, clear on close
  useEffect(() => {
    if (open) {
      // fetch data
      fetchData();
    } else {
      // clear data on close panel
      setError(false);
      setSearchValue("");
      setProjectsIds();
      resetData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, searchValue]);

  // update search value
  const onSearchHandler = debounce((val) => {
    if (val !== searchValue) {
      setSearchValue(val);
      resetData();
    }
  }, 300);

  // project selected
  const onClick = (id, info) => {
    if (onSelect) onSelect(id, info);
  };

  // load more projects
  const onLoadMoreHandler = useCallback(async () => {
    // if all projects fetched or no start after, return
    if (isFetchedAll || !startAfter) return;

    setLoadingMore(true);
    await fetchData({ lastKey: startAfter }, true);
    setLoadingMore(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchedAll, startAfter]);

  // infinite scroll config
  const configScroll = {
    onLoadMore: onLoadMoreHandler,
  };

  // pull up panel config
  const configPullUpPanel = {
    title: STRINGS.selectProject.title,
    onClose,
    onSearch: showSearch && onSearchHandler,
  };

  const renderContent = () => {
    // show error message
    if (error)
      return (
        <EmptyData
          icon={<ErrorIcon />}
          title={STRINGS.error}
          description={STRINGS.selectProject.errorDesc}
        />
      );
    // show loading message
    if (loading) return <Spinner />;
    // show no projects message
    if (projectsIds?.length === 0 && !searchValue)
      return (
        <EmptyData
          icon={<ViewCarouselOutlinedIcon />}
          title={STRINGS.selectProject.noProjectTitle}
          description={STRINGS.selectProject.noProjectDesc}
        />
      );
    // show no projects message on search
    if (projectsIds?.length === 0 && searchValue)
      return (
        <EmptyData
          icon={<SearchIcon />}
          title={STRINGS.selectProject.noProjectSearchTitle}
        />
      );
    return (
      <ProjectContent>
        <InfiniteScroll config={configScroll} size={projectsIds?.length}>
          {projectsIds?.map((id) => (
            <ProjectCard
              key={id}
              projectId={id}
              onClick={onClick}
              mobile={mobile}
            />
          ))}
        </InfiniteScroll>
      </ProjectContent>
    );
  };

  return (
    <PullUpPanel open={open} config={configPullUpPanel} strings={{}}>
      {renderContent()}
      {loadingMore && projectsIds?.length !== 0 && (
        <LoadingMoreContainer>
          <CircularProgress size={30} />
        </LoadingMoreContainer>
      )}
    </PullUpPanel>
  );
};

export default SelectProjectPanel;
