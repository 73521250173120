const GENERAL_STRINGS = {
  button_okay: "OK",

  selectProject: {
    title: "Publish to project",
    noProjectTitle: "You have no available projects to publish to.",
    noProjectDesc: "Please visit MyProjects to create or join a project.",
    noProjectSearchTitle: "No project found",
    filter: "Filter",
    projectTitle: `Project: "{name}" {brand}`,
    confirm: 'Are you sure you want to publish your draft to "{name}"?',
    myprojects: "MyProjects",
    errorDesc:
      "There was an error fetching your projects, please try again later or contact an admin.",
  },

  access: {
    error: "No access to this draft",
  },

  publish: {
    title: 'Publishing to "{name}"',
    desc: "Do not close or refresh this page",
    success: "Story published!",
    error: "An error has occured!",
  },

  editor: {
    mobile: {
      title: "Editing drafts is not available on mobile.",
      desc: "Please visit our website from a laptop or PC to take full advantage of our services.",
    },
  },

  preview: {
    title: "Preview",
    desc: "Preview your experience by scanning the QR code below on your mobile device",
    or: "Or ",
    clickHere: "Click Here",
    viewOnDevice: " to view it on <b>this device</b>",
  },

  draft: {
    manageTitle: "Manage Version",
    restoreCaption: "Restore to version:",
    upgradeCaption: "Upgrade to version:",
    upgradeBackup: "Create a backup",
    alreadyNewest: "Already running the newest version",
    upgraded: "Draft has been successfully upgraded",
    restored: "Draft has been successfully restored",
  },

  error: "An error has occured!",
};

export default GENERAL_STRINGS;
