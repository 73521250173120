import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  useStory,
  useDraft,
  useQR,
  useFontFamilies,
  useFontWeights,
  useStoriesAndCTAs,
  useStoriesAndCTAsAnimations,
  useStoriesAndStickers,
  useStoriesAndInteractions,
  setDocument,
  setSubcollectionDocument,
  addSubCollectionDocument,
  deleteSubCollectionDocument,
  useDraftsAndResolutions,
  updateSubCollectionDocument,
  useDraftSessions,
  useDraftSessionRequest,
  useDraftEdit,
  useDraftsAndLanguages,
  useUserDrafts,
  getDocument,
  getSubCollection,
  useStorybookSettings,
} from "./FirestoreService";
import {
  updateLastModified,
  handleSessionRequest,
  updateDraftAndEditDoc,
  getIframeUrl,
} from "./DraftService";
import { useSignage } from "./SignageService";
import { ROUTES } from "../routes/routes";
import { uploadFile, deleteItem } from "./StorageService";
import { IFRAME_BASE_URL, IFRAME_TEST_URL } from "../config/config";
import { DRAFT_TYPES } from "../config/drafts";
import { useDraftPermissionsContext } from "../context";
import { getDraftEditorUser } from "../utils";
import { useUser } from "./UserService";
import { COLLECTIONS } from "../config/firebase";

const targetOrigin = IFRAME_BASE_URL;
// const targetOrigin = IFRAME_TEST_URL;
const DEBUG = false;

const postMessage = ({ targetWindow, data }) => {
  if (targetWindow && targetWindow.contentWindow) {
    console.debug("CREATE postMessage", data);
    targetWindow.contentWindow.postMessage(data, targetOrigin);
  } else {
    console.log("no iframe available to postMessage");
  }
};

export const resetIframeMessage = () =>
  window.postMessage({ action: "RESET_IFRAME" }, window.location.origin);

export const startPublishMessage = ({ targetWindow }) => {
  postMessage({
    targetWindow,
    data: {
      action: "start_publish",
    },
  });
};

export const endPublishMessage = ({ targetWindow }) => {
  postMessage({
    targetWindow,
    data: {
      action: "end_publish",
    },
  });
};

export const pauseSignMessage = ({ targetWindow }) => {
  postMessage({
    targetWindow,
    data: {
      action: "pause_sign",
    },
  });
};

export const continueSignMessage = ({ targetWindow }) => {
  postMessage({
    targetWindow,
    data: {
      action: "continue_sign",
    },
  });
};

const usePostStoryMessage = ({
  draftId,
  draftType,
  targetWindow,
  iframeReady,
}) => {
  const story = useStory({ draftId, draftType });
  useEffect(() => {
    if (!story || !targetWindow || !iframeReady) return;
    const data = {
      action: "story",
      payload: story,
    };
    postMessage({ targetWindow, data });
  }, [story, targetWindow, iframeReady]);
  return story;
};

const usePostDraftsMessage = ({ draftId, targetWindow, iframeReady }) => {
  const info = useDraft({ draftId });
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "drafts",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostUserDraftsForStorybookMessage = ({
  userId,
  targetWindow,
  iframeReady,
}) => {
  const userDrafts = useUserDrafts({ userId });
  const settings = useStorybookSettings();
  const versions = settings?.supportedTemplates;

  useEffect(() => {
    if (!versions || !userDrafts || !targetWindow || !iframeReady) return;

    // Filter userDrafts by versions
    const filteredUserDrafts = Object.entries(userDrafts).reduce(
      (acc, [id, draft]) => {
        // no draft, maybe deleted
        if (!draft) return acc;

        const templateVersion = draft.templateVersion;
        // get template type from versions to filter drafts by type
        const templateType = versions[draft.type];
        if (templateType) {
          // get versions from template type
          const versions = templateType.versions.map(
            (ver) => `${templateType.prefix}_${ver}`
          );
          // check if template version is in versions
          if (versions.includes(templateVersion)) {
            acc[id] = draft; // Include draft if its version matches
          }
        }
        return acc;
      },
      {}
    );

    const data = {
      action: "user_drafts",
      payload: filteredUserDrafts,
    };
    postMessage({ targetWindow, data });
  }, [userDrafts, versions, targetWindow, iframeReady]);
};

const usePostStorybookDraftsMessage = ({
  story,
  targetWindow,
  iframeReady,
}) => {
  useEffect(() => {
    if (!story || !story.drafts || !targetWindow || !iframeReady) return;
    const drafts = story.drafts.map((draft) => {
      return Object.assign({}, draft, {
        iframeUrl: getIframeUrl({
          draftId: draft.id,
          draftType: draft.type,
          forceTestUrl: true,
        }),
      });
    });
    const data = {
      action: "storybook_drafts",
      payload: drafts,
    };
    postMessage({ targetWindow, data });
  }, [story, targetWindow, iframeReady]);
};

const usePostFontFamiliesMessage = ({ targetWindow, iframeReady }) => {
  const info = useFontFamilies();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_font_families",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostFontWeightsMessage = ({ targetWindow, iframeReady }) => {
  const info = useFontWeights();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_font_weights",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostStoryAndCTAsMessage = ({ targetWindow, iframeReady }) => {
  const info = useStoriesAndCTAs();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_ctas",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostStoryAndCTAsAnimationsMessage = ({
  targetWindow,
  iframeReady,
}) => {
  const info = useStoriesAndCTAsAnimations();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_ctas_animations",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostStoryAndStickersMessage = ({ targetWindow, iframeReady }) => {
  const info = useStoriesAndStickers();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_stickers",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostStoryAndInteractionsMessage = ({ targetWindow, iframeReady }) => {
  const info = useStoriesAndInteractions();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "stories_and_interactions",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostDraftsAndResolutionsMessage = ({ targetWindow, iframeReady }) => {
  const info = useDraftsAndResolutions();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "drafts_and_resolutions",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

const usePostDraftsAndLanguages = ({ targetWindow, iframeReady }) => {
  const info = useDraftsAndLanguages();
  useEffect(() => {
    if (!info || !targetWindow || !iframeReady) return;
    const data = {
      action: "drafts_and_languages",
      payload: info,
    };
    postMessage({ targetWindow, data });
  }, [info, targetWindow, iframeReady]);
};

export const usePostMyStoryData = ({
  draftId,
  draftType,
  targetWindow,
  iframeReady,
}) => {
  usePostStoryMessage({ draftId, draftType, targetWindow, iframeReady });
  usePostDraftsMessage({
    draftId,
    targetWindow,
    iframeReady,
  });
  usePostFontFamiliesMessage({
    targetWindow,
    iframeReady,
  });
  usePostFontWeightsMessage({
    targetWindow,
    iframeReady,
  });
  usePostStoryAndCTAsMessage({
    targetWindow,
    iframeReady,
  });
  usePostStoryAndCTAsAnimationsMessage({
    targetWindow,
    iframeReady,
  });
  usePostStoryAndStickersMessage({
    targetWindow,
    iframeReady,
  });
  usePostStoryAndInteractionsMessage({
    targetWindow,
    iframeReady,
  });
  usePostDraftsAndLanguages({ targetWindow, iframeReady });
};

export const usePostStorybookMessage = ({
  userId,
  draftId,
  draftType,
  targetWindow,
  iframeReady,
}) => {
  const story = usePostStoryMessage({
    draftId,
    draftType,
    targetWindow,
    iframeReady,
  });
  usePostStorybookDraftsMessage({
    story,
    targetWindow,
    iframeReady,
  });
  usePostDraftsMessage({
    draftId,
    targetWindow,
    iframeReady,
  });
  usePostUserDraftsForStorybookMessage({
    userId,
    targetWindow,
    iframeReady,
  });
  usePostDraftsAndLanguages({ targetWindow, iframeReady });
};

const usePostSignageMessage = ({ draftId, targetWindow, iframeReady }) => {
  const signage = useSignage({ draftId });
  useEffect(() => {
    if (!signage || !targetWindow || !iframeReady) return;
    const data = {
      action: "sign",
      payload: signage,
    };
    postMessage({ targetWindow, data });
  }, [signage, targetWindow, iframeReady]);
};

export const usePostMySignageData = ({
  draftId,
  targetWindow,
  iframeReady,
}) => {
  usePostSignageMessage({ draftId, targetWindow, iframeReady });
  usePostDraftsMessage({
    draftId,
    targetWindow,
    iframeReady,
  });
  usePostDraftsAndResolutionsMessage({
    targetWindow,
    iframeReady,
  });
};

const usePostQRMessage = ({ draftId, targetWindow, iframeReady }) => {
  const qr = useQR({ draftId });
  useEffect(() => {
    if (!qr || !targetWindow || !iframeReady) return;
    const data = {
      action: "qr",
      payload: qr,
    };
    postMessage({ targetWindow, data });
  }, [qr, targetWindow, iframeReady]);
};

export const usePostMyQRData = ({ draftId, targetWindow, iframeReady }) => {
  usePostQRMessage({ draftId, targetWindow, iframeReady });
  usePostDraftsMessage({
    draftId,
    targetWindow,
    iframeReady,
  });
  usePostFontFamiliesMessage({
    targetWindow,
    iframeReady,
  });
  usePostFontWeightsMessage({
    targetWindow,
    iframeReady,
  });
};

export const usePostUserPermissions = ({ targetWindow, iframeReady }) => {
  const currentUserPermissions = useDraftPermissionsContext();

  useEffect(() => {
    if (!currentUserPermissions || !targetWindow || !iframeReady) return;
    const data = {
      action: "permissions",
      payload: currentUserPermissions,
    };
    postMessage({ targetWindow, data });
  }, [currentUserPermissions, targetWindow, iframeReady]);
};

export const usePostDraftAndSessions = ({
  draftId,
  userId,
  targetWindow,
  iframeReady,
}) => {
  const draftSessions = useDraftSessions({ draftId });
  const userSessiondId = `${draftId}_${userId}`;
  const userSession = draftSessions?.[userSessiondId];
  const editingUserSession = getDraftEditorUser(draftSessions);
  useEffect(() => {
    if (!targetWindow || !iframeReady) return;
    if (userSession === undefined) return;
    if (editingUserSession === undefined) return;
    const data = {
      action: "session",
      payload: {
        userSession,
        editingUserSession,
      },
    };
    postMessage({ targetWindow, data });
  }, [userSession, targetWindow, iframeReady, editingUserSession]);
};

export const usePostDraftEdit = ({
  draftId,
  currentUserId,
  targetWindow,
  iframeReady,
}) => {
  const draftEdit = useDraftEdit({ draftId });
  const user = useUser({ userId: currentUserId });
  const currentUserPermissions = useDraftPermissionsContext();

  useEffect(() => {
    if (
      !targetWindow ||
      !iframeReady ||
      !user ||
      !draftId ||
      !currentUserPermissions
    )
      return;
    if (draftEdit === undefined) return;
    if (!currentUserPermissions.editor.write) return;
    if (draftEdit === null) {
      // no one is editing draft
      const data = {
        draftId,
        userId: user.usersId,
        name: user.name,
      };
      updateDraftAndEditDoc({ draftId, data });
    }

    postDraftsAndEditData(draftEdit, user);

    function postDraftsAndEditData(draft, user) {
      const data = {
        action: "drafts_and_edit",
        payload: { draftEdit: draft, currentUser: user },
      };
      postMessage({ targetWindow, data });
    }
  }, [
    draftEdit,
    targetWindow,
    iframeReady,
    user,
    draftId,
    currentUserPermissions,
  ]);
};

export const usePostDraftAndRequest = ({
  draftId,
  targetWindow,
  iframeReady,
}) => {
  const draftSessionRequest = useDraftSessionRequest({ draftId });

  useEffect(() => {
    if (!targetWindow || !iframeReady) return;
    if (!draftSessionRequest) return;
    const data = {
      action: "session request",
      payload: draftSessionRequest,
    };
    postMessage({ targetWindow, data });
  }, [draftSessionRequest, targetWindow, iframeReady]);
};

const getDraftStory = async (draftId, draftType) => {
  if (draftType in DRAFT_TYPES) {
    const story = await getDocument({
      collection: DRAFT_TYPES[draftType].collection,
      doc: draftId,
    });
    return story;
  }
  return null;
};

const getStoryForStorybook = async (draftId, draftType) => {
  // no draftId or draftType
  if (!draftId || !draftType) {
    console.warn("getStoryForStorybook: no draftId or draftType");
    return;
  }

  // get story
  const story = await getDraftStory(draftId, draftType);

  if (!story) {
    console.warn("getStoryForStorybook: no story found");
    return;
  }
  // get chapters
  const collection = DRAFT_TYPES[draftType].collection;
  const hasChapters = DRAFT_TYPES[draftType].hasChapters;

  const draftedStory = {};

  Object.assign(draftedStory, story, { mediaDraftId: draftId });

  const chaptersDocs = await getSubCollection({
    collection,
    doc: draftId,
    subcollection: COLLECTIONS.CHAPTERS,
  });

  if (!hasChapters || !chaptersDocs) {
    return draftedStory;
  }

  draftedStory.chapters = [];

  if (draftedStory.chapterOrder && draftedStory.chapterOrder.length) {
    draftedStory.chapterOrder.forEach((chapterId, index) => {
      if (!(chapterId in chaptersDocs)) return;

      const chapter = {};
      Object.assign(chapter, chaptersDocs[chapterId]);
      chapter.id = chapterId;
      draftedStory.chapters[index] = chapter;
      draftedStory.chapters[index].id = chapterId;
    });
  }

  return draftedStory;
};

export const useMessageListener = ({ targetWindow, draftId }) => {
  const [iframeReady, setIframeReady] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const messageHandler = async (message) => {
      if (message.action === "READY") {
        setIframeReady(true);
      } else if (message.action === "GET_DRAFT_STORY") {
        const draftType = message.payload.draftType;
        const draftId = message.payload.draftId;

        const data = message;
        data.response = "success";
        const story = await getStoryForStorybook(draftId, draftType);
        data.promiseValue = story;

        postMessage({ targetWindow, data });
      } else if (message.action === "RESET_IFRAME") {
        setIframeReady(false);
      } else if (message.action === "BACK") {
        history.push(ROUTES.home);
      } else if (message.action === "REDIRECT") {
        const url = message.payload;
        if (!url) return;
        window.open(url, "_blank");
      } else if (message.action === "OPEN_DRAFT") {
        const draftId = message.payload?.draftId;
        if (!draftId) return;
        const url = `${ROUTES.draft}/${draftId}`;
        window.open(url, "_blank");
      } else if (message.action === "session request") {
        handleSessionRequest(message.payload);
      } else if (message.action === "Firestore.setSubcollectionDocument") {
        setSubcollectionDocument(message.payload).then(() => {
          updateLastModified({ draftId });
          const data = message;
          data.response = "success";
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Firestore.setDocument") {
        setDocument(message.payload).then(() => {
          updateLastModified({ draftId });
          const data = message;
          data.response = "success";
          console.log("data", data);
          console.log("targetWindow", targetWindow);
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Firestore.addSubCollectionDocument") {
        addSubCollectionDocument(message.payload).then((docId) => {
          updateLastModified({ draftId });
          const data = message;
          data.response = "success";
          data.promiseValue = docId;
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Firestore.updateSubCollectionDocument") {
        updateSubCollectionDocument(message.payload).then((docId) => {
          const data = message;
          data.response = "success";
          data.promiseValue = docId;
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Firestore.deleteSubCollectionDocument") {
        deleteSubCollectionDocument(message.payload).then(() => {
          updateLastModified({ draftId });
          const data = message;
          data.response = "success";
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Storage.uploadFile") {
        uploadFile(message.payload).then((url) => {
          const data = message;
          data.response = "success";
          data.promiseValue = url;
          postMessage({ targetWindow, data });
        });
      } else if (message.action === "Storage.deleteItem") {
        deleteItem(message.payload).then(() => {
          const data = message;
          data.response = "success";
          postMessage({ targetWindow, data });
        });
      }
    };

    window.onmessage = (e) => {
      if (DEBUG) console.debug("CREATE", e.data);
      // if (e.origin !== targetOrigin) return;
      messageHandler(e.data);
    };
  }, [history, targetWindow, draftId]);

  return iframeReady;
};
