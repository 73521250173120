import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  container: (mobile) => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    transform: "translate(-50%, -50%)",
    padding: mobile ? "0 24px" : 0,
  }),
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  title: {
    fontWeight: "600",
    color: "#323232",
  },
  description: (mobile) => ({
    color: "#323232",
    maxWidth: mobile ? "100%" : "320px",
    textAlign: "center",
  }),
}));

export const EmptyData = (props) => {
  const { title, description, icon } = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles(mobile);

  return (
    <div className={classes.container}>
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={classes.textWrapper}>
        <Typography className={classes.title} variant="body1">
          {title}
        </Typography>
        {description && (
          <Typography className={classes.description} variant="body2">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
};
