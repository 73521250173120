export const COLLECTIONS = {
  DRAFTS_AND_STORIES: "drafts_and_stories",
  DRAFTS_AND_STORIES_V2: "drafts_and_stories_v2",
  DRAFTS_AND_SIGNAGE: "drafts_and_signage",
  DRAFTS_AND_QR: "drafts_and_qr",
  STORIES_AND_FONT_FAMILIES: "stories_and_font_families",
  STORIES_AND_FONT_WEIGHTS: "stories_and_font_weights",
  STORIES_AND_CTAS: "stories_and_ctas",
  STORIES_AND_CTAS_ANIMATIONS: "stories_and_ctas_animations",
  STORIES_AND_STICKERS: "stories_and_stickers",
  STORIES_AND_INTERACTIONS: "stories_and_interactions",
  DRAFTS_AND_RESOLUTIONS: "drafts_and_resolutions",
  CHAPTERS: "chapters",
  STORIES_AND_CHAPTERS: "stories_and_chapters",
  SIGNAGE_AND_CHAPTERS: "signage_and_chapters",
  USERS_AND_DRAFTS: "users_and_drafts",
  USERS: "users",
  DRAFTS: "drafts",
  DRAFTS_AND_SESSIONS: "drafts_and_sessions",
  DRAFTS_AND_EDIT: "drafts_and_edit",
  DRAFTS_AND_REQUESTS: "drafts_and_requests",
  PROJECT_INFO: "projects_and_info",
  DRAFT_PERMISSIONS: "default_draft_permissions",
  DRAFTCODES: "draftcodes",
  DRAFTS_AND_LANGUAGES: "drafts_and_languages",
  SETTINGS: "settings",
};

export const firebaseConfig = process.env.REACT_APP_DEMO_MODE
  ? {
      // myplayertest
      // test config
      apiKey: "AIzaSyAzOd8vZBkpuJioGqyvXFc8zMPq2ut1jfM",
      authDomain: "myplayertest.firebaseapp.com",
      databaseURL: "https://myplayertest.firebaseio.com",
      projectId: "myplayertest",
      storageBucket: "myplayertest.appspot.com",
      messagingSenderId: "209604178303",
      appId: "1:209604178303:web:2b9f57a6a5ce0f8d25aa4a",
    }
  : {
      // myplayerbase
      // production config
      apiKey: "AIzaSyAXRZrgD935w7S3CCIPlzsj5ZcZIL6LG68",
      authDomain: "myplayerbase.firebaseapp.com",
      databaseURL: "https://myplayerbase.firebaseio.com",
      projectId: "myplayerbase",
      storageBucket: "myplayerbase.appspot.com",
      messagingSenderId: "448033846169",
      appId: "1:448033846169:web:61062ef4862d2ce748d908",
    };
