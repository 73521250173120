import React, { memo } from "react";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";

import { useProjectInfo } from "services/FirestoreService";

const isEqual = (p1, p2) => p1.projectId === p2.projectId;

const ProjectCard = ({ projectId, onClick }) => {
  const info = useProjectInfo(projectId);
  if (!info) return null;

  const _onClick = () => {
    if (onClick) onClick(projectId, info);
  };

  return (
    <Box p={1}>
      <Card>
        <CardActionArea onClick={_onClick}>
          <CardMedia style={{ height: 120 }} image={info.imageURL} />
          <CardContent
            style={{
              height: 72,
            }}
          >
            <Typography variant="body1" noWrap>
              {info.name}
            </Typography>
            <Typography variant="body2" noWrap>
              {info.brand}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default memo(ProjectCard, isEqual);
